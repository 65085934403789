<template>
<div>
  <Row :gutter="10">
    <Col span="2">
      <Input v-model="searchKey.name" placeholder="名称" clearable/>
    </Col>
    <Col span="2">
      <Button type="primary" @click="search()">查询</Button>
    </Col>
    <Col span="20" style="text-align: right">
      <Button type="primary" @click="add()">新增</Button>
    </Col>
  </Row>

  <Row style="margin-top: 15px">
    <Col span="24">
      <Table stripe border :columns="columns" :data="list">
        <template slot="no" slot-scope="{index}">{{index + 1}}</template>
        <template slot="hidden" slot-scope="{row}">
          <Checkbox v-model="row.paper_hidden" :true-value="1" :false-value="0" @on-change="onHiddenChange(row.paper_id, $event)"></Checkbox>
        </template>
        <template slot="action" slot-scope="{row}">
          <Button type="primary" @click="look(row.paper_id)" :disabled="row.paper_count < 1">查看提交</Button>
          <Button style="margin-left: 5px" type="primary" @click="mod(row.paper_id)">修改</Button>
          <Button style="margin-left: 5px" type="error" @click="del(row.paper_id)">删除</Button>
        </template>
      </Table>
    </Col>
  </Row>

  <Row style="margin-top: 15px">
    <Col span="24" style="text-align: center">
      <Page show-sizer show-elevator show-total :total="total" :current="searchKey.page" :page-size="searchKey.pagesize" @on-change="pageChange" @on-page-size-change="pageSizeChange"/>
    </Col>
  </Row>
</div>
</template>

<script>
import api from '../../api/xiaochengxu';
export default {
  name: "Paper",
  data: function(){
    return {
      searchKey: {
        name: '',
        page: 1,
        pagesize: 30
      },
      total: 0,
      columns: [
        {title: '#', slot: 'no', width: 80, align: 'center'},
        {title: '名称', key: 'paper_name'},
        {title: '填表人数', key: 'paper_count', width: 100},
        {title: '停用', slot: 'hidden', width: 100},
        {title: '操作', slot: 'action', width: 280, align: 'center'}
      ],
      list: []
    }
  },
  methods: {
    onHiddenChange: function(id, e){
      this.$http.put(api.hiddenPaper, {id:id, hidden:e}).then(response => {
        if(response.data.success) {
          this.$Message.success('修改成功');
        } else {
          this.$Message.error(response.data.err);
          console.log(response);
        }
      })
    },
    look: function(id){
      // this.$http.get(api.signupTable, {params: {id: id}}).then(response => {
      //   window.location.href = this.baseUrl + response.data.path;
      // })
      this.$router.push({path: '/xiaochengxu/paper/submit', query: {id:id}})
    },
    del: function(id){
      this.$Modal.confirm({
        content: '<p>确实要删除这一项吗？此操作也将删除已提交的问卷信息。</p>',
        onOk: () => {
          this.$http.delete(api.delPaper, {data: {id:id}}).then(response => {
            if(response.data.success) {
              this.$Message.success('删除成功');
              this.getList();
            } else {
              this.$Message.error(response.data.err);
              console.log(response);
            }
          })
        },
      });
    },
    add: function(){
      this.$router.push('/xiaochengxu/paper/add')
    },
    pageSizeChange: function(size){
      this.searchKey.pagesize = size;
      this.getList();
    },
    pageChange: function(p){
      this.searchKey.page = p;
      this.getList();
    },
    search: function(){
      this.searchKey.page = 1;
      this.getList();
    },
    getList: function(){
      this.$http.get(api.paperList, {params: this.searchKey}).then(response => {
        if(response.data.success) {
          this.list = response.data.res;
          this.total = response.data.count;
        } else {
          this.$Message.error(response.data.err);
          console.log(response)
        }
      })
    },
    mod: function(id){
      this.$router.push({path: '/xiaochengxu/paper/detail', query: {id:id}})
    }
  },
  mounted() {
    this.getList()
  }
}
</script>

<style scoped>

</style>